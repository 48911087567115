<template>
  <Transition name="fade">
    <Loader v-if="!$beet.isReady"/>
  </Transition>
  <template v-if="$beet.isReady">
    <SiteNav/>
    <router-view/>
    <Footer/>
    <Gdpr :options="{backgroundColor: '#c74223', color: '#fff'}"/>
    <Popup/>
  </template>
</template>

<script>
import {SiteNav} from '@/components/navigation'
import {useLangRouter} from '@/extensions/langRouter'
import {Popup} from '@/components/modal'
import {Footer} from '@/components/sections'
import {Loader} from '@/components/small'
import Gdpr from "@/components/gdpr/Gdpr.vue";

export default {
  name: 'App',
  setup() {
    useLangRouter()
  },
  components: {Gdpr, Footer, Loader, Popup, SiteNav},
  watch: {
    '$i18n.locale': {
      handler: function (to) {
        this.$beet.fetchCoreData(to)
      },
      immediate: true
    }
  },
  mounted() {
    setTimeout(() => {
      const el = this.$route.hash ? document.querySelector(this.$route.hash) : null
      if (el) {
        el.scrollIntoView({behavior: 'smooth', block: 'start'})
      }
    }, 500)
  }
}
</script>
